document.addEventListener('turbo:load', () => {
  $(() => {
    const validContainers = '.c-tabbed';

    // bail if page is not using one of the valid container classes
    if (!$(validContainers).length) { return; }

    const tabStates = [];

    // takes the native element
    // having the boostrap nav
    // classes and containing the
    // data-toggle list items
    function TabController(tabContainer) {
      let activeId;
      const $container = $(tabContainer);
      let subsequentRun = false;
      const initialHash = document.location.hash;

      // set initial activeId value
      // first check Hash
      if (initialHash.includes('#tab_')) {
        activeId = initialHash.substring(5);
      } else if ( // if no hash, check for an "active" tabbed button
        $container.find('.c-tabbed__button.c-tabbed__button--active').length
      ) {
        activeId = $container.
          find('.c-tabbed__button.c-tabbed__button--active').
          first().attr('href').substring(1);
      } else { // if no explicit button is "active", set first one active
        activeId = $container.find('.c-tabbed__button').
          first().attr('href').substring(1);
      }

      const instance = {
        getActiveId: function () {
          return activeId;
        },
        setActiveId: function (id) {
          activeId = id;
        },
        getActiveButton: function () {
          return $container.find(`[href="#${activeId}"]`);
        },
        getContainerElement: function () {
          return $container;
        },
        refreshHash: function () {
          if (subsequentRun) {
            document.location.hash = `tab_${activeId}`;
          } else {
            subsequentRun = true;
          }
        },
        setActive: function () {
          // unset all currently active
          const activeButton = this.getActiveButton();
          const activeTab = $(`#${activeId}`);
          $('.c-tabbed__tab').removeClass('c-tabbed__tab--active');
          $('.c-tabbed__button').removeClass('c-tabbed__button--active');
          // set this one active
          activeTab.addClass('c-tabbed__tab--active');
          activeButton.addClass('c-tabbed__button--active');
        },
        syncWithHash: function () {
          const currentHash = document.location.hash.substring(2);
          if (!currentHash.includes('#tab_')) { return; }
          const elemTarget = currentHash.substring(5);
          // update the activeId for this tabState to the
          // hash value if it contains anchor that matches
          $container.find('a').each((index, elem) => {
            if ($(elem).attr('href') === `#${elemTarget}`) {
              activeId = elemTarget;
            }
          });
        },
        update: function () {
          this.refreshHash();
          this.setActive();
        },
      };

      $container.find('.c-tabbed__button').click(function (buttonClickEvt) {
        buttonClickEvt.preventDefault();
        if ($(this).is('[disabled=disabled]')) { return; }

        instance.setActiveId($(this).attr('href').substring(1));
        instance.update();
      });

      // show active element
      instance.syncWithHash();
      instance.update();

      return instance;
    }

    $(validContainers).each((index, elem) => {
      tabStates.push(TabController(elem));
    });

    window.onhashchange = function () {
      $.each(tabStates, (index, elem) => {
        elem.syncWithHash();
        elem.setActive();
        $(window).scrollTop(elem.offsetTop);
      });
    };
  });
});
