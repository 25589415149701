document.addEventListener('turbo:load', () => {
  setTimeout(() => {
    if ($('.glide--1').length) {
      $('.glide--1').each(function () {
        new Glide(this, {
          type: 'carousel',
          focusAt: 'center',
          perView: 1,
          gap: 12,
          autoplay: 10000,
          perTouch: 1,
          breakpoints: { 1024: { perView: 1 } },
        }).mount();
      });
    }

    if ($('.glide--3').length) {
      $('.glide--3').each(function () {
        new Glide(this, {
          type: 'carousel',
          focusAt: 'center',
          perView: 3,
          gap: 12,
          perTouch: 1,
          breakpoints: { 1024: { perView: 1 } },
        }).mount();
      });
    }

    if ($('.glide--5').length) {
      $('.glide--5').each(function () {
        new Glide(this, {
          type: 'carousel',
          focusAt: 'center',
          perView: 5,
          gap: 12,
          perTouch: 1,
          breakpoints: { 1024: { perView: 1 } },
        }).mount();
      });
    }
  }, 250);
});
