document.addEventListener('turbo:load', () => {
  const $modal = $('.c-modal');

  $modal.click(e => {
    const $target = $(e.target);

    if (!$target.hasClass('c-modal') &&
      !$target.hasClass('c-modal__close-button')) { return; }
    // User clicked the close button...
    $modal.fadeOut('slow');
    $modal.find('.c-modal__content').empty();
  });

  $('.c-modal-candidate__trigger').click(e => {
    let hasContent;
    const $candidate = $(e.target).closest('.c-modal-candidate');
    const $modalContent = $modal.find('.c-modal__content');
    const $candidateText = $candidate.find('.c-modal-candidate__text');
    const $candidateContent = $candidate.
      find('script[type="c-modal-candidate__content"]');
    if ($candidate[0]?.getAttribute('data-size') === 'large') {
      $modalContent[0].classList.add('c-modal__content--large');
    } else if ($candidate[0]?.getAttribute('data-size') === 'medium') {
      $modalContent[0].classList.add('c-modal__content--medium');
    } else {
      $modalContent[0].classList.remove('c-modal__content--large');
    }
    if ($candidateText.length) {
      hasContent = true;
      $modalContent.append($candidate.html());
    } else if ($candidateContent.length) {
      hasContent = true;
      $modalContent.append(
        $candidate.find('script[type="c-modal-candidate__content"]').text(),
      );
    }
    if (!hasContent) { return; }
    $modal.fadeIn('slow');
  });
});
