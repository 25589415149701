import { Controller } from '@hotwired/stimulus';

/*
   This controller is used to handle the submission of supplemental data.
   It replaces app/javascript/modules/_personal-data-submission.js (deleted).

   It takes all the elements with the class 'b-supplemental-data' and creates a
   hidden field with the name 'personal_data_submission[supplemental_data]' containing
   a JSON string with the supplemental data.

   To use this controller, add the controller to the form element:

   ```
     = form_for @personal_data_submission,
       data: { controller: 'personal-data-submission' } \
       do |f|
         ...
   ```

   In most cases, this will be used in conjunction with the 'invisible_recaptcha_controller_tag'
   helper inside the form. E.g.

   ```
     = form_for @personal_data_submission,
       data: { controller: 'personal-data-submission' } \
       do |f|
        ...
        = invisible_recaptcha_controller_tag
        = form.submit "Submit"
   ```

   Note that the 'invisible_recaptcha_controller_tag' helper just inserts the invisible recaptcha
   controller described in the invisible_recaptcha_controller.js file.

 */

function removeField(element) {
  //
  // NOTE: Rails creates hidden fields for checkboxes and radios so we need to remove them.
  //       If we don't remove them, they will be submitted as well and the server side will
  //       complain about the unexpected attributes. This is not a big deal, but it's cleaner.
  //
  const previousElement = element.previousElementSibling;
  if (previousElement &&
    previousElement.name === element.name &&
    previousElement.type === 'hidden') {
    previousElement.remove();
  }
  element.remove();
}

// Connects to data-controller="personal-data-submission"
export default class extends Controller {
  connect() {
    this.element.addEventListener('submit', this.submit.bind(this));
  }

  submit() {
    const supplementalData = {};
    const supplementalDataElements = this.element.querySelectorAll('.b-supplemental-data');

    for (const element of supplementalDataElements) {
      switch (element.type) {
        case 'checkbox':
          supplementalData[element.name] = element.checked;
          break;
        case 'radio':
          if (element.checked) {
            supplementalData[element.name] = element.value;
          }
          break;
        default:
          supplementalData[element.name] = element.value;
      }
    }
    this.createInputForSupplementalData(supplementalData);
    for (const element of supplementalDataElements) {
      removeField(element);
    }
  }

  createInputForSupplementalData(supplementalData) {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = 'personal_data_submission[supplemental_data]';
    input.value = JSON.stringify(supplementalData);
    this.element.appendChild(input);
  }
}
