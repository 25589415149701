document.addEventListener('turbo:load', () => {
  // Height Unification for handling appearance of varying
  // content per .b-unified > .b-unified__item elements
  setTimeout(() => {
    const $sections = $('.b-unified');
    $sections.each(function () {
      let display;
      const $row = $(this);
      let height = 0;
      // get largest height
      $row.find('.b-unified__item').each(function () {
        if (!display) { display = $(this).css('display'); }
        if ($(this).outerHeight() > height) {
          height = $(this).outerHeight();
        }
      });
      // apply to all the candidates
      $row.find('.b-unified__item').each(function () {
        const settings = {
          'min-height': height,
          'display': display, // may need specific solution for firefox
        };
        $(this).css(settings);
      });
    });
  }, 250);
});
