import { Controller } from '@hotwired/stimulus';

// Connects to data-controller='record-link-interaction'
export default class extends Controller {
  static values = {
    interactableId: Number,
    interactableType: String,
    action: String,
    notes: Array,
  };

  connect() {
    const links = this.element.tagName === 'A' ? [this.element] : this.element.querySelectorAll('a');

    this.element.setAttribute('data-controller-connected', 'true');

    links.forEach(link => {
      link.addEventListener('click', this.handleClick.bind(this));
    });
  }

  // eslint-disable-next-line class-methods-use-this
  xsrfToken() {
    if (document.querySelector('meta[name=csrf-token]') === null) {
      return null;
    }
    return document.querySelector('meta[name=csrf-token]').content;
  }

  async handleClick(event) {
    const notes = this.hasNotesValue ? this.notesValue : [event.target.href];
    try {
      fetch('/interactions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': this.xsrfToken(),
        },
        body: JSON.stringify({
          interaction: {
            interactable_id: this.interactableIdValue,
            interactable_type: this.interactableTypeValue,
            action: this.actionValue,
            notes: notes,
          },
        }),
      }).then(response => {
        if (!response.ok) {
          // eslint-disable-next-line no-console
          console.log('interaction record failed');
        }
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  }
}
