import { Controller } from '@hotwired/stimulus';

// Connects to data-controller='click-on-visible'
export default class extends Controller {
  connect() {
    const { element } = this;

    if (window.IntersectionObserver === undefined) {
      return;
    }

    this.observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        const link = element.querySelector('a');
        if (link) {
          this.observer.disconnect();
          link.click();
          element.innerHTML = '<i class="fas fa-spinner fa-spin"></i> Loading...';
        }
      }
    });
    this.observer.observe(element);
  }

  disconnect() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }
}
