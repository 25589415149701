document.addEventListener('turbo:load', () => {
  function isRetinaDisplay() {
    if (window.matchMedia) {
      const mq = window.matchMedia('only screen and (min--moz-device-pixel-ratio: 1.3), ' +
        'only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and ' +
        '(-webkit-min-device-pixel-ratio: 1.3), only screen  and ' +
        '(min-device-pixel-ratio: 1.3), only screen and (min-resolution: 1.3dppx)');
      return ((mq && mq.matches) || (window.devicePixelRatio > 1));
    }
  }

  $.fn.extend({
    processRetina: function () {
      if (isRetinaDisplay()) {
        const $container = $(this);
        $container.find('img').each(function () {
          if (!$(this).data('retina-src')) { return; }
          $(this).attr('src', $(this).data('retina-src'));
        });
      }
    },
  });

  // ensure we reprocess anything lazy-loaded with turbo
  document.addEventListener('turbo:frame-load', loadEvent => {
    $(loadEvent.target).processRetina();
  });

  $('body').processRetina();
});
