document.addEventListener('turbo:load', () => {
  $('.b-submit-on-change').on('change', function(e) {
    const $changed = $(e.target);
    const $form = $(this).closest('.c-form');

    $form.find('.b-submit-on-change').each(function() {
      // Ensure any and all fields cooresponding to the same
      // attribute we changed are not also submitted
      if ($(this).attr('name') !== $changed.attr('name')) { return; }

      if (!$(this).is($changed)) { $(this).attr({ name: '', id: '' }); }
    });

    $form.submit();
  });
});
