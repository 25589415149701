window.setCookie = (name, value, options = {}) => {
  let parameters = '';
  value = value || '';
  if (options.days !== undefined) {
    const date = new Date();
    date.setTime(date.getTime() + (options.days * 24 * 60 * 60 * 1000));
    parameters = `; expires=${date.toUTCString()}`;
  }
  if (options.domain !== undefined) {
    parameters += `; domain=${options.domain}`;
  }
  if (options.path !== undefined) {
    parameters += `; path=${options.path}`;
  } else {
    parameters += '; path=/';
  }
  document.cookie = `${name}=${value}${parameters}`;
};

window.getCookie = name => {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

window.getCookies = () => {
  const cookies = {};
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    const [cookieName, cookieValue] = c.split('=', 2);
    cookies[cookieName] = cookieValue;
  }
  return cookies;
};

window.deleteCookie = (name, options = {}) => {
  let expression = `${name}=; Max-Age=-99999999;`;
  if (options.domain !== undefined) {
    expression += `; domain=${options.domain}`;
  }
  if (options.path !== undefined) {
    expression += ` path=${options.path}`;
  }
  document.cookie = expression;
};
