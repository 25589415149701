document.addEventListener('turbo:load', () => {
  let delay;

  $.fn.extend({
    obscurable: function () {
      const $scope = this;
      const $obscurables = $scope.find('[data-obscurable]');
      $obscurables.each(function() {
        const $obscurable = $(this);
        $obscurable.off('click');
        const options = {
          width: ($obscurable.data('obscurable-width') || 600),
        };

        const $dismissButton =
          $('<button class="b-obscured__dismiss">&times;</button>');
        const $obscured = $obscurable.find('.b-obscured');
        $obscured.css({ width: `${options.width}px` });
        $obscured.append($dismissButton);

        $obscurable.on('click', e => {
          // NOTE: Always hide ALL other opened, obscured content
          $obscurables.find('.b-obscured').hide();
          const $currentTarget = $(e.currentTarget);
          const $target = $(e.target);

          const willHide = $target.hasClass('b-obscured__dismiss');
          const willShow =
            $currentTarget.data('obscurable') !== 'undefined';
          if (willHide) {
            $obscured.hide();
          } else if (willShow) {
            // Only reset the position when clicking outside container
            // and also handle being outside the dismiss button going
            // outside of the view-port
            if (!$target.closest('.b-obscured').length) {
              const viewPortWidth = $(window).width();
              const leftPosition =
                e.pageX + options.width > viewPortWidth ?
                  viewPortWidth - options.width : e.pageX;
              $obscured.css({ 'left': leftPosition, 'top': e.pageY });
            }
            $obscured.show();
          }
        });
      });

      // Clicking on anything else inside the scope
      // passed to the plugin hides visible obscurables
      $scope.on('click', e => {
        const $target = $(e.target);
        if (!$target.parents('[data-obscurable]').length) {
          $obscurables.find('.b-obscured').hide();
        }
      });
    },
  });

  // immediately apply on page load
  $('body').obscurable();

  // ensure we also reprocess anything lazy-loaded with turbo
  // ONLY when we go 1 second without another load
  document.addEventListener('turbo:frame-load', () => {
    clearTimeout(delay);

    delay = setTimeout(() => {
      // TODO: this could be better as it seems like overkill to affect
      // the entire body again for each frame load, but scoping to the
      // target causes the click handler above to be out of scope
      $('body').obscurable();
    }, 1000);
  });
});
