document.addEventListener('turbo:load', () => {
  document.querySelectorAll('.b-update-params-on-change').forEach(el => {
    el.addEventListener('change', e => {
      const field = e.target;
      const url = new URL(window.location.href);

      if (url.searchParams.has(field.name)) {
        url.searchParams.delete(field.name);
      }
      url.searchParams.append(field.name, field.value);
      window.history.pushState(url.toString(), 'unused', url);
    });
  });
});
