document.addEventListener('turbo:load', () => {
  // allows "closing" notifications and removing field errors
  $.fn.extend({
    dismissible: function () {
      this.each(function() {
        const $notification = $(this);
        // only affect notifications with a dismiss button
        if ($notification.find('.c-notification__item--dismiss')) {
          $notification.find('.c-notification__item--dismiss').click(() => {
            if ($notification.closest('.c-notification-box').find('.c-notification').length <= 1) {
              $('.c-notification-box').find('.c-notification-box__head').remove();
              $('.field_with_errors').removeClass('field_with_errors');
            }
            $notification.remove();
          });
        }
      });
    },
  });

  $('.c-notification').dismissible();
});
