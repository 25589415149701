document.addEventListener('turbo:load', () => {
  const accordions = document.getElementsByClassName('b-accordion');
  for (let i = 0; i < accordions.length; i++) {
    (function(j) {
      const accordionTrigger = (accordions[j].getElementsByClassName('b-accordion__trigger'))[0];
      const accordionContent = (accordions[j].getElementsByClassName('b-accordion__content'))[0];
      const accordionArrow = (accordions[j].getElementsByClassName('b-accordion__arrow'))[0];
      accordionTrigger.onclick = function() {
        this.classList.toggle('b-accordion__trigger--expanded');
        accordionArrow.classList.toggle('b-accordion__arrow--expanded');
        if (accordionContent.style.maxHeight) {
          accordionContent.style.maxHeight = null;
        } else {
          accordionContent.style.maxHeight = `${accordionContent.scrollHeight}px`;
        }
      };
    })(i);
  }
});

document.addEventListener('turbo:load', () => {
  const accordions = document.getElementsByClassName('b-calendar-accordion');
  for (let i = 0; i < accordions.length; i++) {
    (function(j) {
      const accordionTrigger = (accordions[j].getElementsByClassName('b-calendar-accordion__trigger'))[0];
      const accordionContent = (accordions[j].getElementsByClassName('b-calendar-accordion__content'))[0];
      accordionTrigger.onclick = function() {
        this.classList.toggle('b-calendar-accordion__trigger--expanded');
        accordionContent.classList.toggle('b-calendar-accordion__content--expanded');
        if (accordionContent.style.maxHeight) {
          accordionContent.style.maxHeight = null;
        } else {
          accordionContent.style.maxHeight = `${accordionContent.scrollHeight}px`;
        }
      };
    })(i);
  }
});
