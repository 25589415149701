import { Controller } from '@hotwired/stimulus';

// Connects to data-controller='auto-submit'
export default class extends Controller {
  static targets = ['submit', 'recordExpansion'];

  submit() {
    this.recordExpansionBeforeSubmit();
    if (this.hasSubmitTarget) {
      this.submitTarget.click();
    } else {
      console.error('Missing submit target in auto-submit controller');
    }
  }

  recordExpansionBeforeSubmit() {
    this.recordExpansionTargets.forEach(target => {
      const value = target.dataset.hiddenInput;
      if (value === undefined) {
        return;
      }
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = value;
      input.value = target.open ? '1' : '0';
      target.appendChild(input);
    });
  }
}
