import { Controller } from '@hotwired/stimulus';

/* Helper: Update Google Tags consent settings: The UI has two checkboxes, one
   for analytics and one for advertising.

   We map these to the Google Tags consent settings.
   1) User grants analytics => we grant the analytics-related permissions.
   2) User grants advertising => we grant all 3 advertising-related permissions.
  */
function mapUserChoicesToGoogleTags(choices) {
  if (window.gtag === undefined) {
    console.warn('Google Tags is not loaded, skipping consent mapping');
    return;
  }

  window.gtag('consent', 'update', {
    'ad_storage': choices.advertising === 'granted' ? 'granted' : 'denied',
    'ad_user_data': choices.advertising === 'granted' ? 'granted' : 'denied',
    'ad_personalization': choices.advertising === 'granted' ? 'granted' : 'denied',
    'analytics_storage': choices.analytics === 'granted' ? 'granted' : 'denied',
  });

  // Clear analytics cookies if user has denied analytics and advertising
  // This may not be necessary but is a good practice to ensure user privacy.
  //
  // We focus on the Google Analytics cookies (_ga, _ga_<container-id>) and
  // the Microsoft Clarity cookies (_clck, _clsk).
  //
  // Gotcha: the analytics cookies are set on the dot domain, so we need
  // to delete them from the dot domain (e.g. '.cloudsecurityalliance.org')
  //
  if (choices.analytics === 'denied' && choices.advertising === 'denied') {
    const cookies = window.getCookies();
    for (const cookieName in cookies) {
      if (cookieName.startsWith('_ga') || cookieName === '_clck' || cookieName === '_clsk') {
        window.deleteCookie(cookieName, { domain: `.${window.location.hostname}` });
      }
    }
  }
  // For advertising cookies, we only need to delete the Google Ads cookie
  if (choices.advertising === 'denied') {
    const cookies = window.getCookies();
    for (const cookieName in cookies) {
      if (cookieName === '_gcl_au') {
        window.deleteCookie(cookieName, { domain: `.${window.location.hostname}` });
      }
    }
  }
}

/* Helper: Update Clarity consent settings: The UI has two checkboxes, one
   for analytics and one for advertising. However, Clarity only has one
   processing purpose: analytics. So we map the analytics checkbox to
   Clarity's consent setting.

   Apparently, Clarity will delete all cookies if the user denies consent.
   So we don't need to worry about clearing cookies here (to be confirmed).
  */
function mapUserChoicesToClarityConsent(choices) {
  if (window.clarity === undefined) {
    console.warn('Clarity is not loaded, skipping consent mapping');
    return;
  }

  if (choices.analytics === 'granted') {
    window.clarity('consent');
  } else {
    window.clarity('consent', false);
  }
}

/* Connects to data-controller="cookie-consent"
   This is expected to be attached to a form element with two checkboxes,
   one for analytics and one for advertising.
  */
export default class extends Controller {
  update(event) {
    event.preventDefault();
    const formData = new FormData(event.target);

    this.choices = { 'analytics': 'denied', 'advertising': 'denied' };
    if (formData.get('analytics') === '1') {
      this.choices.analytics = 'granted';
    }
    if (formData.get('advertising') === '1') {
      this.choices.advertising = 'granted';
    }
    this.saveChoices();
  }

  acceptAll() {
    this.choices = { 'analytics': 'granted', 'advertising': 'granted' };
    this.synchronizeCheckboxesState();
    this.saveChoices();
  }

  rejectAll() {
    this.choices = { 'analytics': 'denied', 'advertising': 'denied' };
    this.synchronizeCheckboxesState();
    this.saveChoices();
  }

  synchronizeCheckboxesState() {
    const analyticsCheckbox = document.querySelector('.cookie-banner form input[name="analytics"]');
    const advertisingCheckbox = document.querySelector('.cookie-banner form input[name="advertising"]');

    if (!analyticsCheckbox || !advertisingCheckbox) {
      return;
    }
    analyticsCheckbox.checked = (this.choices.analytics === 'granted');
    advertisingCheckbox.checked = (this.choices.advertising === 'granted');
  }

  saveChoices() {
    setCookie('cookie_choices', JSON.stringify(this.choices), { days: 365 });
    mapUserChoicesToGoogleTags(this.choices);
    mapUserChoicesToClarityConsent(this.choices);
    this.closeBanner();
    this.reportChoicesAsInteraction();
  }

  closeBanner() {
    const banner = this.element.closest('.cookie-banner');
    if (banner) {
      banner.style.display = 'none';
    }
  }

  /* The fact that we make a POST request to /interactions has the side effect
   * of updating the user's cookies on the server side as well.
   */
  reportChoicesAsInteraction() {
    fetch('/interactions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name=csrf-token]')?.content,
      },
      body: JSON.stringify({
        interaction: {
          action: 'cookie_consent',
          notes: Object.entries(this.choices).flat(),
        },
      }),
    });
  }
}
