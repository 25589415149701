// turbo has conflicts with other libraries in some cases
// let's keep all the workarounds here

// this one is to ensure the /star/registry/org_name view loads correctly
// it can be removed when we replace the registry with hotwire
document.addEventListener('turbo:load', () => {
  const breadcrumbs = document.querySelectorAll('.c-breadcrumbs__item');
  if (!breadcrumbs.length) { return; }
  breadcrumbs.forEach(elem => {
    const anchor = elem.querySelector('a');
    if (!anchor) { return; }

    anchor.dataset.turbo = false;
  });
});
