document.addEventListener('turbo:load', () => {
  $.fn.extend({
    initializeTooltips(options) {
      const gracePeriod = 4000;

      if (options == null) { options = { onClick: false }; }

      if (options.onClick) {
        $(this).find('.b-tooltip--on-click').each(function() {
          const $tooltip = $(this);
          let timer = null;

          // toggles display of the nested
          // tooltip text container
          $tooltip.on('click', () => {
            const $tooltipText = $tooltip.find('.b-tooltip__text');

            if ($tooltipText.is(':hidden')) {
              // add handler to auto-close if user
              // navigates away from the tooltip
              $tooltip.on('mouseleave', () => {
                timer = setTimeout(() => {
                  $tooltipText.css('display', 'none');
                }, gracePeriod);
                $tooltip.off('mouseleave');
              });
              $tooltipText.css({
                left: '50%',
                marginLeft: `${-($tooltipText.innerWidth() / 2)}px`,
                display: 'inline-block',
              });
            } else {
              clearTimeout(timer);
              $tooltipText.css('display', 'none');
            }
          });
        });
      } else {
        $(this).find('.b-tooltip:not(".b-tooltip--on-click")').each(function() {
          let $tooltip = $(this);

          $tooltip.off('mousemove mouseenter mouseleave');
          $tooltip.on('mousemove', function(event) {
            const $tooltipText = $(this).find('.b-tooltip__text');
            const triggerX = $(this).offset().left;
            const triggerY = $(this).offset().top;
            const newY =
              (event.pageY - triggerY) - ($tooltipText.height() + 20);
            const newX =
              (event.pageX - triggerX) - ($tooltipText.innerWidth() / 2);

            $tooltipText.css({ 'left': newX, 'top': newY });
          });
          $tooltip.on('mouseenter', event => {
            const $tooltipText = $tooltip.find('.b-tooltip__text');
            $tooltip = $(event.currentTarget);

            $tooltip.off('mouseleave');
            $tooltipText.css('display', 'inline-block');
            setTimeout(() => {
              $tooltipText.css('display', 'none');
            }, gracePeriod);
          });
        });
      }
    },
  });

  $('body').initializeTooltips();
});
