document.addEventListener('turbo:load', () => {
  (function() {
    const showChar = 520;
    const showCharNoCollapse = 150;
    const ellipsestext = '&hellip;';
    const arrow = '<i class="fas fa-long-arrow-alt-right"></i>';

    $('.b-limited-content').each(function() {
      const $content = $(this).html();
      if ($content.length > showChar) {
        const $limitedContent = $content.substr(0, showChar);
        const $fullContent = $content;
        const html =
          `<div class="b-limited-content__item">${
            $limitedContent
          }<span class="moreellipses">${
            ellipsestext}&nbsp;&nbsp;<a href="" ` +
              'class="b-limited-content__item--trigger ' +
              'b-limited-content__item--consealed">Read more</a>' +
            '</span>' +
          '</div>' +
          `<div class="b-limited-content__item" style="display:none;">${
            $fullContent
          }<br><a href="" class="u-mt16 c-button c-button--secondary ` +
            'b-limited-content__item--trigger ' +
            'b-limited-content__item--revealed">Read less</a>' +
          '</div>';
        $(this).html(html);
      }
    });

    $('.b-limited-content--no-collapse').each(function() {
      const $content = $(this).html();
      if ($content.length > showCharNoCollapse) {
        const $limitedContent = $content.substr(0, showCharNoCollapse);
        const $fullContent = $content;
        const html =
          `<div class="b-limited-content__item">${
            $limitedContent
          }<span class="moreellipses">${
            ellipsestext}&nbsp;&nbsp;<a href="" ` +
              'class="b-limited-content__item--trigger ' +
              `b-limited-content__item--consealed">${arrow}</a>` +
            '</span>' +
          '</div>' +
          `<div class="b-limited-content__item" style="display:none;">${
            $fullContent
          }</div>`;
        $(this).html(html);
      }
    });

    $('.b-limited-content__item--trigger').click(function() {
      const $thisEl = $(this);
      const $revealed = $thisEl.closest('.b-limited-content__item');
      const tX = '.b-limited-content__item';

      if ($thisEl.hasClass('b-limited-content__item--revealed')) {
        $revealed.prev(tX).toggle();
        $revealed.slideToggle();
      } else {
        $revealed.toggle();
        $revealed.next(tX).fadeToggle();
      }
      return false;
    });
  })();
});
