document.addEventListener('turbo:load', () => {
  // TODO: This has causes numerous edge cases. Let's reenable after
  //       we have more control of which terms are ideal for auto-linking.
  /* eslint-disable */
  return;

  // Any paths we do not want the automatic glossary
  // linking on should be added to IGNORED_PATHS
  const IGNORED_PATHS = [
    // dev
    '/framework',
    // Registry too important to risk a regression
    '/star/registry',
    // Pages opted out from the start
    '/cloud-security-glossary',
    '/legal',
    '/press-releases',
    // More pages opted out February '22 per Airtable issue
    // https://airtable.com/appk3wpZPPjLrfcF1/\
    //   tbl8ZBrB4eQK88faN/viwIOobx5OOzKOvrA/reciSigcjTQ2vFcbW
    '/star/certified-star-auditors',
    '/education/training-partners',
    '/education/instructors',
    '/education/ccak',
    '/research/ron-knode/service-award',
    '/research/fellowship',
    '/about/board-of-directors',
    '/about/csa-staff',
    // bug with ghosted buttons (finding "host" in p .c-button--ghost)
    '/membership/enterprises',
    '/membership/solution-providers',
    '/privacy/gdpr',

  ];
  const IGNORED_TAGS = [ /<img /g ];
  const WRAPPED_TAGS = [
    { opening: /<a.*href/g, closing: /<\/a\>/g },
  ];
  const TAGS = 'p, li';

  const glossaryTerms =
    JSON.parse(document.getElementById('glossary-linking').innerText);

  // Str -> Str
  //
  // Returns true if :path matches the URL we're visiting
  function isCurrentPath (path) {
    return (window.location.pathname.indexOf(path) !== -1);
  }

  // Str -> Str
  //
  // Returns HTML string of term wrapped in the
  // class structure expected for glossary auto-linking
  function linkTermToGlossary (term, match) {
    return '<a class="c-tag c-tag--glossary" ' +
      'href="/cloud-security-glossary#' + term.identifier +
      '" target="_blank" rel="noopener" ' +
      'title="Cloud Security Glossary: ' +
      term.name + '">' + match + '<\/a>';
  }

  // Str, Str -> Object
  //
  // Returns array of the head of the content before :s,
  // followed by :s, followed by the rest of the content
  function getParts (str, s) {
    let content = { before: '', target: s, after: ''};
    let index = str.toLowerCase().indexOf(s.toLowerCase());
    if (index === -1) { console.log('index was not found...'); }
    content.before = str.substring(0, index) || '';
    content.after = str.substring(index + s.length, str.length);

    return content;
  }

  // Str, Str -> Bool
  //
  // Returns true if :str contains a tag without a
  // required accompanying matching closing tag
  function isMaybeInsideAnchor (str, s) {
    let parts = getParts(str, s);
    let openingTagCount = parts.before.match(/<a.*href/g)?.length || 0;
    let closingTagCount = parts.before.match(/<\/a>/g)?.length || 0;
    return openingTagCount !== closingTagCount;
  }

  function isMaybeAnImage (str) {
    return str.match(/<img(?!.*>)/g)?.length > 0;
  }

  // Str, Str -> Bool
  //
  // Returns true if the preceeding text of :str before
  // occurrence of :s contains any item in patterns Array
  function isPossibleEdgeCase (str, s) {
    let parts = getParts(str, s);
    let patterns = [ 'c-logo--member'];
    for (let i = 0; i < patterns.length; i++) {
      if (!parts.before.indexOf(patterns[i]) === -1) { return true; }
    }
    return false;
  }

  // Str, Str -> Str
  //
  // Runs helper function applyRegex for each match
  // of :term->name found in :str
  function getModifiedHTML (str, term) {
    function applyRegex (match, i, original) {
      if (isMaybeAnImage(original.substring(0, i), match)) { return match; }
      if (isPossibleEdgeCase(original, match)) { return match; }
      if (isMaybeInsideAnchor(original, match)) { return match; }
      return linkTermToGlossary(term, match);
    }
    return str.replace(new RegExp(`${term.name}`, 'ig'), applyRegex);
  }

  // Parses HTML for the main content container
  // and wraps valid glossary terms found in a
  // link to the /cloud-security-glossary page
  function applyLinking () {
    let scope = document.
      querySelectorAll('.c-layout-main .o-area ');
    if (typeof scope === 'undefined') { return; }

    for (let i = 0; i < glossaryTerms.length; i++) {
      let term = glossaryTerms[i];
      let name = term.name;
      let loweredName = name.toLowerCase();

      scope.forEach(area => area.querySelectorAll(TAGS).forEach(element => {
        let html = element.innerHTML;
        if (element.closest('.b-obscured')) { return; }
        if (html.toLowerCase().indexOf(loweredName) === -1) { return; }
        element.innerHTML = getModifiedHTML(html, term);
      }));
    }
  }

  if (IGNORED_PATHS.some(isCurrentPath)) { return; }
  if (window.location.pathname === '/') { return; }

  applyLinking();
  /* eslint-enable */
});
