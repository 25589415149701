document.addEventListener('turbo:load', () => {
  const expandables = document.querySelectorAll('.b-read-more');
  expandables.forEach(element => {
    let length = parseInt(element.dataset.truncateLength, 10);

    if (length.isNaN) {
      length = 100;
    }

    if (element.innerText.length <= length) {
      return;
    }

    const head = element.innerText.substring(0, length);
    const tail = element.innerText.substring(length);
    const headEl = document.createElement('span');
    const expandEl = document.createElement('a');
    const tailEl = document.createElement('span');
    const reduceEl = document.createElement('a');

    headEl.textContent = head;
    expandEl.textContent = '[show more]';
    expandEl.className = 'b-read-more__expand';
    expandEl.href = '#';
    tailEl.textContent = tail;
    tailEl.style.display = 'none';
    reduceEl.textContent = '[show less]';
    reduceEl.className = 'b-read-more__reduce';
    reduceEl.style.display = 'none';
    reduceEl.href = '#';

    element.innerHTML = '';
    element.appendChild(headEl);
    element.appendChild(expandEl);
    element.appendChild(tailEl);
    element.appendChild(reduceEl);

    expandEl.addEventListener('click', event => {
      event.preventDefault();
      expandEl.style.display = 'none';
      tailEl.style.display = 'inline';
      reduceEl.style.display = 'inline';
    });

    reduceEl.addEventListener('click', event => {
      event.preventDefault();
      tailEl.style.display = 'none';
      reduceEl.style.display = 'none';
      expandEl.style.display = 'inline';
    });
  });
});
