// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// RAILS
require('@rails/ujs');
import '@hotwired/turbo-rails';
require('./jquery');

require('@rails/activestorage').start();
require('./channels');
// Rich Text Areas via ActionText (uses trix on the front-end)
require('trix');
require('@rails/actiontext');

// VENDOR
require('html5shiv');

require('./modules/vendor/jquery.cookie');

require('braintree-web/client');
require('braintree-web/hosted-fields');
require('braintree-web/data-collector');
require('braintree-web/paypal');
import braintree from 'braintree-web';
window.braintree = braintree;

import '@fortawesome/fontawesome-free/js/all';

import Glide from '@glidejs/glide';
window.Glide = Glide;

require('slicknav/dist/jquery.slicknav.js');

// CUSTOM
function importAll(r) { r.keys().forEach(r); }
importAll(require.context('./modules/', true, /\.(js|coffee)$/));
import './controllers';
