import { Controller } from '@hotwired/stimulus';

// Connects to data-controller='modal'
export default class extends Controller {
  static values = {
    triggerSelector: String,
  };

  connect() {
    if (!this.hasTriggerSelectorValue) {
      console.warn('A modal controller was created using ' +
        'data-controller="modal" without speifying a trigger selector value');
      return;
    }

    document.querySelectorAll(this.triggerSelectorValue).forEach(trigger => {
      trigger.addEventListener('click', this.click.bind(this));
    });

    this.element
      .querySelectorAll('.o-modal__close, .o-modal__background, .c-button, .o-modal__cancel')
      .forEach(trigger => {
        trigger.addEventListener('click', () => {
          this.element.classList.remove('o-modal--active');
        });
      });
  }

  click(event) {
    event.preventDefault();
    this.element.classList.add('o-modal--active');
    const content = this.element.querySelector('.o-modal__content');
    if (content.classList.contains('o-modal__content--right')) {
      if (window.innerWidth >= 768) {
        content.style.top = `${event.clientY - 12}px`;
        content.style.left = `${event.clientX + 20}px`;
      } else {
        content.style.top = `${event.clientY + 12}px`;
        content.style.right = '12px';
        content.style.left = 'auto';
      }
    } else if (content.classList.contains('o-modal__content--left')) {
      if (window.innerWidth >= 768) {
        content.style.top = `${event.clientY - 12}px`;
        content.style.right = `${window.innerWidth - event.clientX + 12}px`;
      } else {
        content.style.top = `${event.clientY + 12}px`;
        content.style.right = 'auto';
        content.style.left = '12px';
      }
    }
  }
}
