document.addEventListener('DOMContentLoaded', () => {
  let $backToTop = $('.u-scroll-to-top');
  if (!$backToTop.length) { return; }

  let currentPosition = 0;
  let recentlyScrolled,
    triggerHeight,
    pageHeight,
    refreshDisplay;

  function scrolled() {
    recentlyScrolled = true;
    setTimeout(() => { recentlyScrolled = false; }, 500);
  }

  function initializeState() {
    $backToTop = $('.u-scroll-to-top');
    currentPosition = $(window).scrollTop();
    pageHeight = $('.c-layout-main').height() +
      $('.c-layout-footer').height();
    triggerHeight = pageHeight * 0.05;
    recentlyScrolled = false;
    listen();
    document.addEventListener('scroll', toggleDisplay);
    $backToTop.off('click');
    $backToTop.click(function() {
      scrolled();
      $(this).hide('fast');
      $('html, body').animate({ scrollTop: 0 }, 600);
    });
  }

  function toggleDisplay() {
    currentPosition = $(window).scrollTop();

    // so as to not bring the browser to its knees
    if (recentlyScrolled) { return; }

    if (currentPosition > triggerHeight) {
      scrolled();
      $backToTop.show('slow');
    } else if ($backToTop.is(':visible')) {
      $backToTop.hide('fast');
    }
  }

  // Ensure button doesn't get stuck 'on' by
  // checking its position every second
  function listen() {
    currentPosition = $(window).scrollTop();
    refreshDisplay = setInterval(() => {
      if (currentPosition <= triggerHeight &&
        $backToTop.is(':visible')
      ) { $backToTop.hide('fast'); }
    }, 1000);
  }

  // reset the state each following turbo load/visit
  document.addEventListener('turbo:load', () => {
    clearInterval(refreshDisplay);
    initializeState();
  });

  // set the initial state on first load
  initializeState();
});
