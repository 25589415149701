document.addEventListener('turbo:load', () => {
  const YEAR_SECONDS = 31557600;
  const DAY_SECONDS = 86400;
  const HOUR_SECONDS = 3600;

  const countdowns =
    [...document.getElementsByClassName('b-countdown')];

  function getCountdownBlockHTML(label) {
    return `<div class="b-countdown__${label.toLowerCase()}">${
      getDigitHTML(0, label)}</div>`;
  }

  function pad(value) {
    if ((`${value}`).length > 1) { return `${value}`; }
    return `0${value}`;
  }

  function getDigitHTML(value, label) {
    const chars = Array.prototype.map.call(pad(value), digit =>
      `<div class="b-countdown__digit">${digit}</div>`,
    );
    return `${chars.join('')
    }<div class="b-countdown__label">${label}</div>`;
  }

  countdowns.forEach(countdown => {
    countdown.innerHTML =
      getCountdownBlockHTML('Years') +
      getCountdownBlockHTML('Days') +
      getCountdownBlockHTML('Hours') +
      getCountdownBlockHTML('Minutes') +
      getCountdownBlockHTML('Seconds');

    // Convert date to browser-friendly value by dropping
    // the timezone portion.
    const targetDate = countdown.dataset.to.
      replace(/-/g, '/').split(' ').slice(0, -1).join(' ');
    const later = new Date(Date.parse(targetDate));
    // After conversion, timezone still applied client-side
    // Sun Apr 14 2030 00:00:00 GMT-0700 (Pacific Daylight Time)

    const years = countdown.
      getElementsByClassName('b-countdown__years')[0];
    const days = countdown.
      getElementsByClassName('b-countdown__days')[0];
    const hours = countdown.
      getElementsByClassName('b-countdown__hours')[0];
    const minutes =
      countdown.getElementsByClassName('b-countdown__minutes')[0];
    const seconds =
      countdown.getElementsByClassName('b-countdown__seconds')[0];

    const interval = setInterval(() => {
      let remainingValue,
        yearsValue,
        daysValue,
        hoursValue,
        minutesValue;

      remainingValue = parseInt((later - new Date()) / 1000, 10);

      if (remainingValue > 0) {
        yearsValue = Math.floor(remainingValue / YEAR_SECONDS);
        remainingValue -= (yearsValue * YEAR_SECONDS);
        daysValue = Math.floor(remainingValue / DAY_SECONDS);
        remainingValue -= (daysValue * DAY_SECONDS);
        hoursValue = Math.floor(remainingValue / HOUR_SECONDS);
        remainingValue -= (hoursValue * HOUR_SECONDS);
        minutesValue = Math.floor(remainingValue / 60);
        remainingValue -= (minutesValue * 60);

        years.innerHTML = getDigitHTML(yearsValue, 'Years');
        days.innerHTML = getDigitHTML(daysValue, 'Days');
        hours.innerHTML = getDigitHTML(hoursValue, 'Hours');
        minutes.innerHTML = getDigitHTML(minutesValue, 'Minutes');
        seconds.innerHTML = getDigitHTML(remainingValue, 'Seconds');
      } else {
        years.innerHTML = getDigitHTML(0, 'Years');
        days.innerHTML = getDigitHTML(0, 'Days');
        hours.innerHTML = getDigitHTML(0, 'Hours');
        minutes.innerHTML = getDigitHTML(0, 'Minutes');
        seconds.innerHTML = getDigitHTML(0, 'Seconds');
        clearInterval(interval);
        setInterval(() => {
          countdown.style.opacity = 0;
          setTimeout(() => {
            countdown.style.opacity = 1;
          }, 500);
        }, 1000);
      }
    }, 1000);
  });
}, false);
